import React, { useState } from 'react';
import Button from '../Button';

import FormInputField from '../FormInputField/FormInputField';
import Config from '../../config.json';

import * as styles from './Contact.module.css';

const Contact = (props) => {
  const initialState = {
    name: '',
    phone: '',
    email: '',
    comment: '',
  };

  const [contactForm, setContactForm] = useState(initialState);

  const handleChange = (id, e) => {
    const tempForm = { ...contactForm, [id]: e };
    setContactForm(tempForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setContactForm(initialState);
  };

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <h4>Get In Touch with Us</h4>
        <p>
          Free free to come on into the store, call us to book an appointment,
          or ask any questions!
        </p>
      </div>
      <div className={styles.section}>
        <h4>Address + Hours</h4>

        <p>
          <a
            href="https://goo.gl/maps/vRYxrwbsiuHKDkgSA"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            1328 W Artesia Blvd, Gardena, CA 90248{' '}
          </a>{' '}
          <br />
        </p>
        <br />
        <p>
        {Config.hours.map((hour) => (
        <span key={hour}>{hour}<br /></span>
      ))}
          <br />
          {Config.hours_closed}
        </p>
        <br />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1662.0940418727964!2d-118.29673619073866!3d33.87194938563287!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2ca9e49e52725%3A0x8a01ecbf632f2cbe!2sTC%20Herbs%20%26%20Acupuncture!5e0!3m2!1sen!2sus!4v1690435370555!5m2!1sen!2sus"
          width="550"
          height="400"
          allowfullscreen="yes"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className={styles.section}>
        <h4>Phone</h4>
        <p>
          <b>TC Herbs:</b> <a href="tel:310-327-0336">(310) 327-0336</a>{' '}
        </p>
        <p>
          <b>Shen Clinic: </b>
          <a href="tel:310-515-6062">(310) 515-6062</a> <br />
        </p>
      </div>

      <div className={styles.section}>
        <h4>Email</h4>
        <p>
          You can email us at{' '}
          <a href="mailto: tcherbs@gmail.com">tcherbs@gmail.com</a>
        </p>
      </div>

      <div className={styles.section}>
        <h4>Give us a Review!</h4>
        <p>
          <a
            href="https://www.yelp.com/biz/tc-herbs-and-health-gardena"
            target="_blank"
            rel="noopener noreferrer"
          >
            TC Herbs Yelp
          </a>
        </p>
        <p>
          <a
            href="https://www.yelp.com/biz/shen-clinic-gardena-2?osq=shen+clinic"
            target="_blank"
            rel="noopener noreferrer"
          >
            Shen Clinic Yelp
          </a>
        </p>
        <p>
          <a
            href="https://g.page/r/Cb4sL2O_7AGKEB0/review"
            target="_blank"
            rel="noopener noreferrer"
          >
            TC Herbs Google Review
          </a>
        </p>

        <b>中文：</b>
        <a
          href="https://www.chineseinla.com/doctor/task_view/id_13628/%e6%b2%88%e6%b0%8f%e4%b8%ad%e5%8c%bb%e5%a6%87%e7%a7%91.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          洛杉矶华人资讯网
        </a>
      </div>
      <br />
      <h5>Feel free to send us any feedback here:</h5>

      <div className={styles.contactContainer}>
        <form
          onSubmit={(e) => handleSubmit(e)}
          name={'Feedback-Form'}
          netlify
          data-netlify-honeypot="bot-field"
          data-netlify="true"
        >
          <div className={styles.contactForm}>
            <FormInputField
              id={'name'}
              value={contactForm.name}
              handleChange={(id, e) => handleChange(id, e)}
              type={'text'}
              labelName={'Name'}
            />
            <FormInputField
              id={'email'}
              value={contactForm.email}
              handleChange={(id, e) => handleChange(id, e)}
              type={'email'}
              labelName={'Email'}
            />
            <div className={styles.commentInput}>
              <FormInputField
                id={'comment'}
                value={contactForm.comment}
                handleChange={(id, e) => handleChange(id, e)}
                type={'textarea'}
                labelName={'Comments / Questions'}
                required
              />
            </div>
          </div>
          <Button
            className={styles.customButton}
            level={'primary'}
            type={'buttonSubmit'}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
